function burgerMenu() {
  let menu = document.querySelector('.burger-menu')
  let button = document.querySelector('.burger-menu_button')
  let links = document.querySelector('.burger-menu_link')
  let overlay = document.querySelector('.burger-menu_overlay')

  button.addEventListener('click', (e) => {
    e.preventDefault()
    toggleMenu()
  })

  links.addEventListener('click', () => toggleMenu())
  overlay.addEventListener('click', () => toggleMenu())

  function toggleMenu() {
    menu.className === 'burger-menu_active'
      ? (menu.className = 'burger-menu')
      : (menu.className = 'burger-menu_active')
  }
}

burgerMenu()